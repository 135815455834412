import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/", redirect: "/home"
  },
  {
    path: "/home", redirect: '/home/goods', component: () => import("../views/Home.vue"),
    children: [
      { path: "goods", name: "goods", component: () => import("../views/goods/Goods.vue") },
    ]
  },
  {
    path: "/configureScript",
    component: () => import("@/views/configureScript/configureScript.vue"),
    meta: {
      title: '配置话术',
      keepAlive: true,
    }
  },
  { path: "/*", component: () => import("../views/Error404.vue") }
]

const router = new VueRouter({
  routes
})

export default router
